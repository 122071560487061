export default {
  name: "WarrantSearch",
  created() {
    this.$nextTick(function () {
      // localStorage.clear();
      this.getSelectorSearchData();
      this.getSearchData();
      this.printTable();
    });
  },
  mounted() {
    this.$nextTick(function () {
      this.selector();
      localStorage.removeItem("recordArr");
      $(".search-column-selection-box-h-c").prop("checked", true);
      $(".search-column-selection-box-c-b")
        .find('input[type="checkbox"]')
        .prop("checked", true);
    });
  },
  data() {
    return {
      tagArr: [],
      dataObj: {
        underlyingNanme: ["all"],
        issuerName: ["RHB"],
        sortBy: "wcode",
        sortOrder: "asc",
        estimate: true
      },
      underlyingsData: null,
      iFInFo: false,
      mouse: false,
      multitermH: 0,
      underlyingsArr: [
        {
          id: 0,
          name: this.$t("m.allShares"),
          ric: "all",
          visible: true
        }
      ],
      underlyingsArrVice: [],
      MoreFilterT: false
    };
  },
  methods: {
    // 重整 bid & ask
    info: function () {
      let timer1;
      this.getSearchData();
      $("#info").attr("disabled", true);
      clearTimeout(timer1);
      //设置一次性定时器
      timer1 = setTimeout(function () {
        $("#info").attr("disabled", false);
      }, 5000);
    },
    handleMouse: function (flag) {
      this.mouse = flag;
    },
    selector: function () {
      let _this = this,
        Tarr,
        Farr;
      // 第一个 下拉菜单
      $(".search-triangle-one").click(function () {
        event.stopPropagation();
        if ($(".search-select-one-r").is(":visible") == true) {
          $(".search-select-one-r").hide();
        } else {
          $(".search-select-one-r").show();
        }
        Tarr = _this.underlyingsArrVice.filter(tiem => {
          return tiem.visible == true;
        });
        Farr = _this.underlyingsArrVice.filter(tiem => {
          return tiem.visible == false;
        });
        _this.underlyingsArr = [...Tarr, ...Farr];
      });
      $("html").click(function (event) {
        if ($(event.target).parents(".search-select-one-r").length == 0)
          $(".search-select-one-r").hide();
      });

      // 第二个 下拉菜单
      $(".search-triangle-two").click(function () {
        event.stopPropagation();
        if ($(".search-select-two-r").is(":visible") == true) {
          $(".search-select-two-r").hide();
        } else {
          $(".search-select-two-r").show();
        }
      });
      $("html").click(function (event) {
        if ($(event.target).parents(".search-select-two-r").length == 0)
          $(".search-select-two-r").hide();
      });

      // 第三个 下拉菜单
      var BT = true;

      $(".search-select-three").click(function () {
        _this.MoreFilterT = !_this.MoreFilterT;
        if (BT) {
          $(".search-button-triangle").css({
            transform: "rotateX(180deg)"
          });
          $(".search-multiterm").show();
          _this.multitermH = $(".search-multiterm").outerHeight(true);
          BT = !BT;
        } else {
          $(".search-button-triangle").css({
            transform: "rotateX(0deg)"
          });
          $(".search-multiterm").hide();
          _this.multitermH = 0;
          BT = !BT;
        }
        _this.FormdynamicChange();
      });

      // More Filter 下 全选和单选
      //全局全选与单个的关系
      $(".search-column-selection-box-h-c").click(function () {
        var $checkboxs = $(".search-column-selection-box-c-b").find(
          'input[type="checkbox"]'
        );
        if ($(this).is(":checked")) {
          $checkboxs.prop("checked", true);
        } else {
          $checkboxs.prop("checked", false);
        }
        _this.FormdynamicChange();
      });

      $(".search-column-selection-box-s").each(function () {
        $(this).click(function () {
          console.log(1);
          if (
            $(this)
              .find("input")
              .is(":checked")
          ) {
            //判断：所有单个是否勾选
            var len = $(".search-column-selection-box-s").length;
            var num = 0;
            $(".search-column-selection-box-s").each(function () {
              if (
                $(this)
                  .find("input")
                  .is(":checked")
              ) {
                num++;
              }
            });
            if (num == len) {
              $(".search-column-selection-box-h-c").prop("checked", true);
            }
          } else {
            //单个取消勾选，全局全选取消勾选
            $(".search-column-selection-box-h-c").prop("checked", false);
          }
          _this.FormdynamicChange();
        });
      });
    },
    getSelectorSearchData: function () {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: this.$js.webservice + "GetScreenerData",
        data: { token: "webkey", init: "1" },
        success: res => {
          if (res.status == "SUCCESS") {
            let _this = this;
            this.selectorAssignment(res.underlyings, res.issuers);
            $.each(res.underlyings, function (i, c) {
              _this.underlyingsArr.push({
                id: i + 1,
                name: `(${c.underlying_code}) ${c.underlying_name}`,
                ric: c.underlying_ticker,
                visible: false
              });
            });
            this.underlyingsArrVice = this.underlyingsArr;
          }
        },
        error: function (XMLHttpRequest) { }
      });
    },
    selectorAssignment: function (underlyings, rhb) {
      this.underlyingsData = underlyings;
      let then = this;
      $(".search-select-one-text")
        .find(".name")
        .val(this.$t("m.allShares"));
      // $('.search-select-two-text')
      //     .find('.name')
      //     .text(then.$t('m.allIssuers'))
      $(".search-select-one-text")
        .find(".name")
        .val();
      var rhbText = "";
      rhbText += '<div class="search-select-two-multiple">';
      rhbText +=
        '<input type="checkbox" value="checkbox" style="margin-right: 5%; transform:translateY(2px)" class="parent_check_two">';
      rhbText += "<span>" + this.$t("m.allIssuers") + "</span>";
      rhbText += "</div>";
      // rhbText += '<div class="search-select-two-img"><img src="image/Warrant_Search_RHB.png" alt="" width="100%"></div>'
      rhbText += '<div class="checkbox rhbbox_two">';
      $.each(rhb, (i, r) => {
        rhbText += '<div class="search-select-two-multiple">';
        rhbText +=
          '<input type="checkbox" value="checkbox" style="margin-right: 5%; transform:translateY(2px)" class="son_check_two">';
        if (r === "MACQ") {
          rhbText += "<span>" + this.$t("m.MACQUARIE") + "</span >";
        } else {
          if (r === "MSI") {
            rhbText += "<span>MAYBANK</span >";
          } else {
            rhbText += "<span>" + r.toUpperCase() + "</span >";
          }
        }
        rhbText += "</div >";
      });
      rhbText += "</div>";
      $(".search-select-two-r").append(rhbText);
      // 初始化
      // 第一下拉菜单的全选和单选
      //全局全选与单个的关系
      // if (
      // 	$('.search-select-one-text')
      // 		.find('.name')
      // 		.val() === this.$t('m.allShares')
      // ) {
      // 	// $('.search-select-one-multiple input').prop('checked', true)
      // 	// then.dataObj.underlyingNanme = []
      // 	// $.each(underlyings, function(index, value) {
      // 	// 	then.dataObj.underlyingNanme.push(value.underlying_ticker)
      // 	// })
      // 	// this.iFInFo = true
      // }
      let OidVal;
      $(".search-select-one-text")
        .find(".name")
        .on("focus", function () {
          OidVal = $(this).val();
          $(this).val("");
        })
        .on("blur", function () {
          $(this).val(OidVal);
        });
      // console.log(then.dataObj.underlyingNanme)
      // 第二下拉菜单的全选和单选
      //全局全选与单个的关系
      $(".parent_check_two").click(function () {
        var $checkboxs = $(".rhbbox_two").find('input[type="checkbox"]');
        if ($(this).is(":checked")) {
          $checkboxs.prop("checked", true);
        } else {
          $checkboxs.prop("checked", false);
        }
      });
      var rLenTwo = $(".rhbbox_two").find(".search-select-two-multiple").length;
      if (
        then.dataObj.issuerName[0] == "all" ||
        then.dataObj.issuerName == rLenTwo
      ) {
        then.dataObj.issuerName = rhb;
        $(".search-select-two-r")
          .find("input")
          .prop("checked", true);
      }
      for (var j = 0, max2 = rLenTwo; j < max2; j++) {
        if (
          $(".rhbbox_two")
            .find(".search-select-two-multiple span")
            .eq(j)
            .text() === "RHB"
        ) {
          $(".rhbbox_two")
            .find(".search-select-two-multiple input")
            .eq(j)
            .prop("checked", true);
          $(".search-select-two-text")
            .find(".name")
            .text("RHB");
          then.dataObj.issuerName = ["RHB"];
        }
      }
      $(".son_check_two").each(function () {
        $(this).click(function () {
          if ($(this).is(":checked")) {
            //判断：所有单个是否勾选
            var len = $(".son_check_two").length;
            var num = 0;

            $(".son_check_two").each(function () {
              if ($(this).is(":checked")) {
                num++;
              }
            });
            if (num == len) {
              $(".parent_check_two").prop("checked", true);
            }
            if (
              $(this)
                .next()
                .text()
                .replace(/\s*/g, "") === then.$t("m.MACQUARIE")
            ) {
              then.dataObj.issuerName.push("MACQ");
            } else if (
              $(this)
                .next()
                .text()
                .replace(/\s*/g, "") === "MAYBANK"
            ) {
              then.dataObj.issuerName.push("MSI");
            } else {
              then.dataObj.issuerName.push(
                $(this)
                  .next()
                  .text()
              );
            }
            then.getSearchData();
          } else {
            //单个取消勾选，全局全选取消勾选
            $(".parent_check_two").prop("checked", false);
            let issuerText = $(this)
              .next()
              .text()
              .replace(/\s*/g, "");
            $.each(then.dataObj.issuerName, function (index, value) {
              if (value === "MACQ") {
                value = then.$t("m.MACQUARIE");
              }
              if (value === "MSI") {
                value = "MAYBANK";
              }
              if (value === issuerText) {
                then.dataObj.issuerName.splice(index, 1);
              }
            });
            then.getSearchData();
          }

          if (then.dataObj.issuerName === rhb.length) {
            $(".search-select-two-text")
              .find(".name")
              .text(then.$t("m.allIssuers"));
          } else {
            let ricName = [];
            $.each(then.dataObj.issuerName, (i, c) => {
              if (c == "MACQ") {
                ricName[i] = then.$t("m.MACQUARIE");
              } else if (c == "MSI") {
                ricName[i] = "MAYBANK";
              } else {
                ricName[i] = c;
              }
            });
            $(".search-select-two-text")
              .find(".name")
              .text(ricName.toString().toUpperCase());
          }
          if (then.dataObj.issuerName.length === 0) {
            $(".search-select-two-text")
              .find(".name")
              .text(" ");
          }
        });
      });
      $(".parent_check_two").change(function () {
        then.dataObj.issuerName = [];
        if ($(this).is(":checked")) {
          $.each(rhb, function (index, value) {
            then.dataObj.issuerName.push(value);
          });
          $(".search-select-two-text")
            .find(".name")
            .text(then.$t("m.allIssuers"));
          then.getSearchData();
        } else {
          $(".search-select-two-text")
            .find(".name")
            .text(" ");
          then.getSearchData();
        }
      });
      this.selectSort();
    },
    // Shares下拉菜单功能
    sharesDropDownListAbility: function (data, e) {
      data.visible = e.target.checked;
      if (data.ric == "all") {
        $.each(this.underlyingsArr, (i, c) => {
          c.visible = e.target.checked;
        });
        if (e.target.checked) {
          this.dataObj.underlyingNanme = ["all"];
          $(".search-select-one-text")
            .find(".name")
            .val(this.$t("m.allShares"));
        } else {
          this.dataObj.underlyingNanme = [];
          $(".search-select-one-text")
            .find(".name")
            .val("");
        }
      } else {
        if (this.dataObj.underlyingNanme[0] == "all") {
          this.dataObj.underlyingNanme = [];
        }
        this.underlyingsArrVice[0].visible = false;
        if (e.target.checked) {
          this.dataObj.underlyingNanme[data.id - 1] = data.ric;
        } else {
          this.dataObj.underlyingNanme[data.id - 1] = "";
        }
        $(".search-select-one-text")
          .find(".name")
          .val(
            this.dataObj.underlyingNanme.filter(tiem => tiem !== "").toString()
          );
      }
      this.underlyingsArrVice[data.id].visible = e.target.checked;
      this.getSearchData();
    },
    // Shares 搜索
    seekShares: function (e) {
      this.underlyingsArr = this.underlyingsArrVice.filter(function (tiem) {
        return (
          tiem.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 ||
          e.target.value.toLowerCase().indexOf(tiem.name.toLowerCase()) >= 0
        );
      });
      $(".search-select-one-r").show();
    },
    selectSort: function () {
      let then = this;
      var sortArr = [
        "wcode",
        "isr",
        "ub",
        "ep",
        "dwps",
        "mdate",
        "sen",
        "eg",
        "iv",
        "mdate",
        "dlt",
        "pre",
        "tt",
        "mn",
        "bv",
        "bp",
        "ap",
        "av",
        "tv",
        "pc"
      ];
      $(".search-form-name")
        .find(".asc")
        .on("click", function () {
          var ascIndex = $(".search-form-name")
            .find(".asc")
            .index($(this));
          console.log(sortArr[ascIndex]);
          then.dataObj.sortBy = sortArr[ascIndex];
          then.dataObj.sortOrder = "asc";
          then.dataObj.estimate = false;
          then.getSearchData();
        });
      $(".search-form-name")
        .find(".desc")
        .on("click", function () {
          var descIndex = $(".search-form-name")
            .find(".desc")
            .index($(this));
          then.dataObj.sortBy = sortArr[descIndex];
          then.dataObj.sortOrder = "desc";
          then.dataObj.estimate = false;
          then.getSearchData();
        });
    },
    getSearchData: function () {
      let {
        underlyingNanme,
        issuerName,
        sortBy,
        sortOrder,
        estimate
      } = this.dataObj;
      $(".search-form-box tbody").empty();
      $.ajax({
        type: "POST",
        dataType: "json",
        url: this.$js.webservice + "GetScreenerData",
        data: {
          token: "webkey",
          underlying: underlyingNanme.toString(),
          type: "all",
          issuer: issuerName.toString().replace(/,/g, ";"),
          maturity: "all",
          moneyness: "all",
          effectiveGearing: "all",
          expiry: "all",
          sortBy: sortBy,
          sortOrder: sortOrder
        },
        success: res => {
          if (res.status == "SUCCESS") {
            this.formData(res.data, estimate);
          }
        },
        error: function (XMLHttpRequest) { }
      });
    },
    showForm: function () {
      let then = this;
      var $check_all = $("#check_all");
      var $default = $("#default");
      var $multiple_choice = $(".search-column-selection-box-s");
      var $form = $("#searchForm");
      var recordArr = [];
      var columuArr = [
        [
          then.$t("m.underlyingBid").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          true
        ],
        [
          then.$t("m.exercisePrice").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          true
        ],
        [
          then.$t("m.exerciseRatio").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          true
        ],
        [
          then.$t("m.expiryDate").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          true
        ],
        [
          then.$t("m.sensitivity").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          true
        ],
        [
          then.$t("m.effectiveGearing").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          true
        ],
        [
          then.$t("m.impliedVolatility").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          true
        ],
        [
          then.$t("m.timetoMaturity").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          true
        ],
        [then.$t("m.delta").replace(/\s*/g, ""), "h2_one", "c_one_1", false],
        [then.$t("m.premium").replace(/\s*/g, ""), "h2_one", "c_one_1", false],
        [then.$t("m.theta").replace(/\s*/g, ""), "h2_one", "c_one_1", false],
        [
          then.$t("m.moneyness").replace(/\s*/g, ""),
          "h2_one",
          "c_one_1",
          false
        ],
        [
          then.$t("m.bidVolume").replace(/\s*/g, ""),
          "h2_two",
          "c_two_1",
          false
        ],
        [then.$t("m.bidPrice").replace(/\s*/g, ""), "h2_two", "c_two_1", true],
        [then.$t("m.askPrice").replace(/\s*/g, ""), "h2_two", "c_two_1", true],
        [
          then.$t("m.askVolume").replace(/\s*/g, ""),
          "h2_two",
          "c_two_1",
          false
        ],
        [
          then.$t("m.tradedVolume").replace(/\s*/g, ""),
          "h2_two",
          "c_two_1",
          false
        ],
        [
          then.$t("m.priceChange").replace(/\s*/g, ""),
          "h2_two",
          "c_two_1",
          true
        ]
      ];
      if (localStorage.getItem("recordArr") !== null) {
        console.log(1);
        columuArr = JSON.parse(localStorage.getItem("recordArr"));
      }

      $(".search-column-selection-box-h-c").prop("checked", false);
      // 初始化
      $.each(columuArr, function (m, n) {
        switch (n[0]) {
          case then.$t("m.premium").replace(/\s*/g, ""):
            // 头部
            $form
              .find(".h2 #" + n[1])
              .eq(m)
              .removeClass(n[1]);
            // 标题
            $form
              .find(".h2 #" + n[1])
              .eq(m)
              .hide();
            // 内容
            for (var i = 0; i < $(".form_c").length; i++) {
              $(".form_c")
                .eq(i)
                .find("." + n[2])
                .eq(m)
                .hide();
            }
            //去钩
            $multiple_choice
              .find("input")
              .eq(m)
              .prop("checked", false);
            break;
          case then.$t("m.delta").replace(/\s*/g, ""):
            // 头部
            $form
              .find(".h2 #" + n[1])
              .eq(m)
              .removeClass(n[1]);
            // 标题
            $form
              .find(".h2 #" + n[1])
              .eq(m)
              .hide();
            // 内容
            for (var i = 0; i < $(".form_c").length; i++) {
              $(".form_c")
                .eq(i)
                .find("." + n[2])
                .eq(m)
                .hide();
            }
            //去钩
            $multiple_choice
              .find("input")
              .eq(m)
              .prop("checked", false);
            break;
          case then.$t("m.theta").replace(/\s*/g, ""):
            // 头部
            $form
              .find(".h2 #" + n[1])
              .eq(m)
              .removeClass(n[1]);
            // 标题
            $form
              .find(".h2 #" + n[1])
              .eq(m)
              .hide();
            // 内容
            for (var i = 0; i < $(".form_c").length; i++) {
              $(".form_c")
                .eq(i)
                .find("." + n[2])
                .eq(m)
                .hide();
            }
            //去钩
            $multiple_choice
              .find("input")
              .eq(m)
              .prop("checked", false);
            break;
          case then.$t("m.moneyness").replace(/\s*/g, ""):
            // 头部
            $form
              .find(".h2 #" + n[1])
              .eq(m)
              .removeClass(n[1]);
            // 标题
            $form
              .find(".h2 #" + n[1])
              .eq(m)
              .hide();
            // 内容
            for (var i = 0; i < $(".form_c").length; i++) {
              $(".form_c")
                .eq(i)
                .find("." + n[2])
                .eq(m)
                .hide();
            }
            //去钩
            $multiple_choice
              .find("input")
              .eq(m)
              .prop("checked", false);
            break;
          case then.$t("m.bidVolume").replace(/\s*/g, ""):
            // 头部
            $form
              .find(".h2 #" + n[1])
              .eq(m - 12)
              .removeClass(n[1]);
            // 标题
            $form
              .find(".h2 #" + n[1])
              .eq(m - 12)
              .hide();
            // 内容
            for (var i = 0; i < $(".form_c").length; i++) {
              $(".form_c")
                .eq(i)
                .find("." + n[2])
                .eq(m - 12)
                .hide();
            }
            //去钩
            $multiple_choice
              .find("input")
              .eq(m)
              .prop("checked", false);
            break;
          case then.$t("m.askVolume").replace(/\s*/g, ""):
            // 头部
            $form
              .find(".h2 #" + n[1])
              .eq(m - 12)
              .removeClass(n[1]);
            // 标题
            $form
              .find(".h2 #" + n[1])
              .eq(m - 12)
              .hide();
            // 内容
            for (var i = 0; i < $(".form_c").length; i++) {
              $(".form_c")
                .eq(i)
                .find("." + n[2])
                .eq(m - 12)
                .hide();
            }
            //去钩
            $multiple_choice
              .find("input")
              .eq(m)
              .prop("checked", false);
            break;
          case then.$t("m.tradedVolume").replace(/\s*/g, ""):
            // 头部
            $form
              .find(".h2 #" + n[1])
              .eq(m - 12)
              .removeClass(n[1]);
            // 标题
            $form
              .find(".h2 #" + n[1])
              .eq(m - 12)
              .hide();
            // 内容
            for (var i = 0; i < $(".form_c").length; i++) {
              $(".form_c")
                .eq(i)
                .find("." + n[2])
                .eq(m - 12)
                .hide();
            }
            //去钩
            $multiple_choice
              .find("input")
              .eq(m)
              .prop("checked", false);
            break;
        }
      });

      this.formHead();
      // 全选
      $check_all.click(function () {
        if ($(this).is(":checked")) {
          // 头部
          $form.find(".h2 #h2_one").addClass("h2_one");
          $form.find(".h2 #h2_two").addClass("h2_two");
          // 标题
          $form.find(".h2 #h2_one").show();
          $form.find(".h2 #h2_two").show();
          // 内容
          $form.find(".form_c .c_one_1").show();
          $form.find(".form_c .c_two_1").show();
          $form.find(".form_c .c_two_1:last-child").hide();
          $.each(columuArr, function (index, value) {
            value[3] = true;
          });
          localStorage.setItem("recordArr", JSON.stringify(columuArr));
        } else {
          // 头部
          $form.find(".h2 #h2_one").removeClass("h2_one");
          $form.find(".h2 #h2_two").removeClass("h2_two");
          // 标题
          $form.find(".h2 #h2_one").hide();
          $form.find(".h2 #h2_two").hide();
          // 内容
          $form.find(".form_c .c_one_1").hide();
          $form.find(".form_c .c_two_1").hide();
          $.each(columuArr, function (index, value) {
            value[3] = false;
          });
          localStorage.setItem("recordArr", JSON.stringify(columuArr));
        }
        then.formHead();
        if ($("#searchForm").outerWidth(true) > 1283) {
          $(".search-form-box").css({ "overflow-x": "scroll" });
        } else {
          $(".search-form-box").css({ "overflow-x": "hidden" });
        }
        then.FormdynamicChange();
      });

      $default.click(function () {
        $multiple_choice.find("input").attr("disabled", $(this).is(":checked"));
        $check_all.attr("disabled", $(this).is(":checked"));
        if ($(this).is(":checked")) {
          $.each(columuArr, (g, j) => {
            if (
              j[0] !== then.$t("m.premium").replace(/\s*/g, "") &&
              j[0] !== then.$t("m.delta").replace(/\s*/g, "") &&
              j[0] !== then.$t("m.theta").replace(/\s*/g, "") &&
              j[0] !== then.$t("m.moneyness").replace(/\s*/g, "") &&
              j[0] !== then.$t("m.bidVolume").replace(/\s*/g, "") &&
              j[0] !== then.$t("m.askVolume").replace(/\s*/g, "") &&
              j[0] !== then.$t("m.tradedVolume").replace(/\s*/g, "")
            ) {
              j[3] = true;
              //打勾
              $multiple_choice
                .find("input")
                .eq(g)
                .prop("checked", true);
              if (g > 11) {
                g = g - 12;
              }
              // 头部
              $form
                .find(".h2 #" + j[1])
                .eq(g)
                .addClass(j[1]);
              // 标题
              $form
                .find(".h2 #" + j[1])
                .eq(g)
                .show();
              // 内容
              $.each($(".form_c"), function (n) {
                $(".form_c")
                  .eq(n)
                  .find("." + j[2])
                  .eq(g)
                  .show();
              });
            } else {
              switch (j[0]) {
                case then.$t("m.premium").replace(/\s*/g, ""):
                  j[3] = false;
                  // 头部
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .removeClass(j[1]);
                  // 标题
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .hide();
                  // 内容
                  $.each($(".form_c"), function (i) {
                    $(".form_c")
                      .eq(i)
                      .find("." + j[2])
                      .eq(g)
                      .hide();
                  });
                  //去钩
                  $multiple_choice
                    .find("input")
                    .eq(g)
                    .prop("checked", false);
                  break;
                case then.$t("m.delta").replace(/\s*/g, ""):
                  j[3] = false;
                  // 头部
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .removeClass(j[1]);
                  // 标题
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .hide();
                  // 内容
                  $.each($(".form_c"), function (i) {
                    $(".form_c")
                      .eq(i)
                      .find("." + j[2])
                      .eq(g)
                      .hide();
                  });
                  //去钩
                  $multiple_choice
                    .find("input")
                    .eq(g)
                    .prop("checked", false);
                  break;
                case then.$t("m.theta").replace(/\s*/g, ""):
                  j[3] = false;
                  // 头部
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .removeClass(j[1]);
                  // 标题
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .hide();
                  // 内容
                  $.each($(".form_c"), function (i) {
                    $(".form_c")
                      .eq(i)
                      .find("." + j[2])
                      .eq(g)
                      .hide();
                  });
                  //去钩
                  $multiple_choice
                    .find("input")
                    .eq(g)
                    .prop("checked", false);
                  break;
                case then.$t("m.moneyness").replace(/\s*/g, ""):
                  j[3] = false;
                  // 头部
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .removeClass(j[1]);
                  // 标题
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .hide();
                  // 内容
                  $.each($(".form_c"), function (i) {
                    $(".form_c")
                      .eq(i)
                      .find("." + j[2])
                      .eq(g)
                      .hide();
                  });
                  //去钩
                  $multiple_choice
                    .find("input")
                    .eq(g)
                    .prop("checked", false);
                  break;
                case then.$t("m.bidVolume").replace(/\s*/g, ""):
                  console.log(g);
                  j[3] = false;
                  // 头部
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .removeClass(j[1]);
                  // 标题
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .hide();
                  // 内容
                  $.each($(".form_c"), function (i) {
                    $(".form_c")
                      .eq(i)
                      .find("." + j[2])
                      .eq(g)
                      .hide();
                  });
                  //去钩
                  $multiple_choice
                    .find("input")
                    .eq(g)
                    .prop("checked", false);
                  break;
                case then.$t("m.askVolume").replace(/\s*/g, ""):
                  j[3] = false;
                  // 头部
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .removeClass(j[1]);
                  // 标题
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .hide();
                  // 内容
                  $.each($(".form_c"), function (i) {
                    $(".form_c")
                      .eq(i)
                      .find("." + j[2])
                      .eq(g)
                      .hide();
                  });
                  //去钩
                  $multiple_choice
                    .find("input")
                    .eq(g)
                    .prop("checked", false);
                  break;
                case then.$t("m.tradedVolume").replace(/\s*/g, ""):
                  j[3] = false;
                  // 头部
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .removeClass(j[1]);
                  // 标题
                  $form
                    .find(".h2 #" + j[1])
                    .eq(g)
                    .hide();
                  // 内容
                  $.each($(".form_c"), function (i) {
                    $(".form_c")
                      .eq(i)
                      .find("." + j[2])
                      .eq(g)
                      .hide();
                  });
                  //去钩
                  $multiple_choice
                    .find("input")
                    .eq(g)
                    .prop("checked", false);
                  break;
              }
            }
          });
          $check_all.prop("checked", false);
          localStorage.setItem("recordArr", JSON.stringify(columuArr));
          then.formHead();
        }
        then.FormdynamicChange();
      });
      // 单选
      $multiple_choice.click(function () {
        if (!$default.is(":checked")) {
          var checked = $(this)
            .find("input")
            .is(":checked");
          var multipleName = $(this)
            .text()
            .replace(/\s*/g, "");
          $.each(columuArr, (v, i) => {
            if (checked) {
              switch (multipleName) {
                case i[0]:
                  i[3] = true;
                  localStorage.setItem("recordArr", JSON.stringify(columuArr));
                  if (v > 11) {
                    v = v - 12;
                  }
                  // 头部
                  $form
                    .find(".h2 #" + i[1])
                    .eq(v)
                    .addClass(i[1]);
                  // 标题
                  $form
                    .find(".h2 #" + i[1])
                    .eq(v)
                    .show();
                  // 内容
                  // $form.find('.form_c #' + i[2]).eq(v).show()
                  for (var n = 0; n < $(".form_c").length; n++) {
                    $(".form_c")
                      .eq(n)
                      .find("." + i[2])
                      .eq(v)
                      .show();
                  }
                  break;
              }
            } else {
              switch (multipleName) {
                case i[0]:
                  i[3] = false;
                  localStorage.setItem("recordArr", JSON.stringify(columuArr));
                  if (v > 11) {
                    v = v - 12;
                  }
                  // 头部
                  $form
                    .find(".h2 #" + i[1])
                    .eq(v)
                    .removeClass(i[1]);
                  // 标题
                  $form
                    .find(".h2 #" + i[1])
                    .eq(v)
                    .hide();
                  // 内容
                  // $form.find('.form_c #' + i[2]).eq(v).hide()
                  for (var n = 0; n < $(".form_c").length; n++) {
                    $(".form_c")
                      .eq(n)
                      .find("." + i[2])
                      .eq(v)
                      .hide();
                  }
                  break;
              }
            }
          });
          then.formHead();
          if ($("#searchForm").outerWidth(true) > 1283) {
            $(".search-form-box").css({ "overflow-x": "scroll" });
          } else {
            $(".search-form-box").css({ "overflow-x": "hidden" });
          }
        }
        then.FormdynamicChange();
      });

      if (!this.estimate) {
        var num = 0;
        recordArr = JSON.parse(localStorage.getItem("recordArr"));
        $.each(recordArr, function (index, value) {
          if (value[3]) {
            num++;
            if (index > 11) {
              //加钩
              $multiple_choice
                .find("input")
                .eq(index)
                .prop("checked", true);
              // 头部
              $form
                .find(".h2 #" + value[1])
                .eq(index - 12)
                .addClass(value[1]);
              // 标题
              $form
                .find(".h2 #" + value[1])
                .eq(index - 12)
                .show();
              // 内容
              for (var n = 0; n < $(".form_c").length; n++) {
                $(".form_c")
                  .eq(n)
                  .find("." + value[2])
                  .eq(index - 12)
                  .show();
              }
            } else {
              //加钩
              $multiple_choice
                .find("input")
                .eq(index)
                .prop("checked", true);
              // 头部
              $form
                .find(".h2 #" + value[1])
                .eq(index)
                .addClass(value[1]);
              // 标题
              $form
                .find(".h2 #" + value[1])
                .eq(index)
                .show();
              // 内容
              for (var n = 0; n < $(".form_c").length; n++) {
                $(".form_c")
                  .eq(n)
                  .find("." + value[2])
                  .eq(index)
                  .show();
              }
            }
          } else {
            if (index > 11) {
              //去钩
              $multiple_choice
                .find("input")
                .eq(index)
                .prop("checked", false);
              // 头部
              $form
                .find(".h2 #" + value[1])
                .eq(index - 12)
                .removeClass(value[1]);
              // 标题
              $form
                .find(".h2 #" + value[1])
                .eq(index - 12)
                .hide();
              // 内容
              for (var n = 0; n < $(".form_c").length; n++) {
                $(".form_c")
                  .eq(n)
                  .find("." + value[2])
                  .eq(index - 12)
                  .hide();
              }
            } else {
              //去钩
              $multiple_choice
                .find("input")
                .eq(index)
                .prop("checked", false);
              // 头部
              $form
                .find(".h2 #" + value[1])
                .eq(index)
                .removeClass(value[1]);
              // 标题
              $form
                .find(".h2 #" + value[1])
                .eq(index)
                .hide();
              // 内容
              for (var n = 0; n < $(".form_c").length; n++) {
                $(".form_c")
                  .eq(n)
                  .find("." + value[2])
                  .eq(index)
                  .hide();
              }
            }
          }
        });
        if (num === 18) {
          $(".search-column-selection-box-h-c").prop("checked", true);
        } else if (num === 0) {
          $(".search-column-selection-box-h-c").prop("checked", false);
        }
        this.formHead();
      }
    },
    formData: function (data) {
      var formArr = [];
      for (var i = 0, max = data.length; i < max; i++) {
        // var underlying_bid = this.$js.spaceData(data[i].underlying_bid);
        // if (underlying_bid !== "N/A") {
        //   underlying_bid = this.$js.formatNumber(underlying_bid, 3, 0);
        // }
        var maturity = this.$js.spaceData(data[i].maturity);
        if (maturity !== "N/A") {
          maturity = this.transitionData(
            this.$js.changeToLocalTime(this.$js.formatDetailDate(maturity))
          );
        }
        var sensitivity = data[i].sensitivity;
        if (sensitivity !== "N/A") {
          sensitivity = this.$js.formatNumber(sensitivity, 1, 0);
        }
        var effectiveGearing = this.$js.spaceData(data[i].effectiveGearing);
        if (effectiveGearing !== "N/A") {
          effectiveGearing = this.$js.formatNumber(effectiveGearing, 1, 0);
        }
        // var impliedVolalitiy = this.$js.formatNumber(data[i].impliedVolalitiy, 1, 0)
        // if (impliedVolalitiy == 0) {
        //     impliedVolalitiy = 0
        // }
        var impliedVolalitiy = this.$js.spaceData(data[i].impliedVolalitiy);
        if (impliedVolalitiy !== "N/A") {
          impliedVolalitiy = this.$js.formatNumber(impliedVolalitiy, 1, 0);
        }
        var priceChange = this.$js.formatNumber(data[i].priceChange, 1, 0);
        if (priceChange > 0) {
          priceChange = this.$js.changeRate(priceChange);
        } else if (priceChange == 0) {
          priceChange = 0;
        }
        var theta = this.$js.spaceData(data[i].theta);
        if (theta !== "N/A") {
          if (Math.abs(Number(data[i].theta)) < 0.0001) {
            theta = "-0.0000";
          } else {
            theta = this.$js.formatNumber(data[i].theta, 4, 0);
          }
        }
        var premium = this.$js.spaceData(data[i].premium);
        if (premium !== "N/A") {
          premium = this.$js.formatNumber(premium, 1, 0);
        }
        formArr[i] = [
          this.$js.spaceData(data[i].dsply_nmll),
          this.$js.rewriteIssueName(this.$js.spaceData(data[i].issuer)) == "MSI"
            ? "Maybank"
            : this.$js.rewriteIssueName(this.$js.spaceData(data[i].issuer)),
          this.$js.formatNumber(
            this.$js.spaceData(data[i].underlying_bid),
            0,
            1
          ),
          this.$js.formatNumber(data[i].exercisePrice, 0, 1),
          this.$js.formatNumberNo(data[i].conv_ratio, 4),
          maturity,
          sensitivity,
          effectiveGearing,
          impliedVolalitiy,
          this.$js.spaceData(data[i].TimeToMaturity),
          this.$js.spaceData(data[i].delta),
          // data[i].askPrice_f,
          premium,
          theta,
          this.$js.spaceData(data[i].moneyness_c),
          this.$js.formatNumber(data[i].bidVolume / 100, 0, 1),
          this.$js.formatNumber(data[i].bidPrice, 0, 1),
          this.$js.formatNumber(data[i].askPrice, 0, 1),
          this.$js.formatNumber(data[i].askVolume / 100, 0, 1),
          this.$js.formatNumber(this.$js.clearRM(data[i].tradeVolume), 0, 1),
          priceChange,
          data[i].soldout,
          data[i].ric
        ];
      }
      this.assignment(formArr);
    },
    // 时间转换
    transitionData: function (time) {
      var MMM = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      return (
        time.getDate() +
        " " +
        MMM[time.getMonth()] +
        " " +
        time
          .getFullYear()
          .toString()
          .substring(4 - 2, 4)
      );
    },
    // 表格动态变化
    FormdynamicChange: function () {
      let _this = this;
      let formBoxW = $(".search-form-box").width();
      let formW = $("#searchForm").width();
      let formT = $("#SFormBox").offset().top;
      let formArrows = $("#warransearch").find(".form_arrows");
      let scrollLeft = $(".search-form-box").scrollLeft();
      let titleTextH = $("#warransearch")
        .find(".title_text")
        .outerHeight(true);
      let selectH = $(".search-select-show").outerHeight(true);
      // 复制
      // warrntName
      setTimeout(function () {
        $(".warrntName").empty();
        $(".warrntName").append(
          $(".search-form-box")
            .find("#searchForm")
            .clone()
        );
        $(".warrntName")
          .find("table")
          .width(
            $("#SFormBox")
              .find("table")
              .outerWidth(true)
          );
        $(".warrntName").width(
          $("#searchForm")
            .find(".h2")
            .find("th")
            .eq(0)
            .outerWidth(true)
        );
        $(".warrntName").css({
          top: formT + "px"
        });
      }, 10);

      //warrntNameTop
      setTimeout(function () {
        $(".warrntNameTop").empty();
        $(".warrntNameTop").append(
          $(".search-form-box")
            .find("#searchForm")
            .clone()
        );
        $(".warrntNameTop")
          .find("table")
          .width(
            $("#SFormBox")
              .find("table")
              .outerWidth(true)
          );
        $(".warrntNameTop").width(
          $("#searchForm")
            .find(".h2")
            .find("th")
            .eq(0)
            .outerWidth(true)
        );
        $(".warrntNameTop").height(
          $("#SFormBox")
            .find("thead")
            .outerHeight(true)
        );
        $(".warrntNameTop").css({
          top: $(".header-box").innerHeight() + "px"
        });
      }, 10);

      // theadClone
      setTimeout(function () {
        $(".theadClone").empty();
        $(".theadClone").height(
          $("#SFormBox")
            .find("thead")
            .outerHeight(true)
        );
        $(".theadClone").append(
          $(".search-form-box")
            .find("#searchForm")
            .clone()
        );
        $(".theadClone")
          .find("table")
          .width(
            $("#SFormBox")
              .find("table")
              .outerWidth(true)
          );
        $(".theadClone").width(formBoxW);
        $(".theadClone").css({
          top: $(".header-box").innerHeight() + "px"
        });
      }, 10);

      if ($(window).scrollTop() > formT) {
        $(".theadClone").show();
        $(".warrntNameTop").show();
      } else {
        $(".theadClone").hide();
        $(".warrntNameTop").hide();
      }

      // 三角箭头
      if ($(document).scrollTop() >= $("#SFormBox").outerHeight(true) - 500) {
        // 三角箭头
        if (_this.MoreFilterT) {
          formArrows.css({
            top: formT - 600 + "px"
          });
        } else {
          formArrows.css({
            top: formT - 200 + "px"
          });
        }
      } else {
        // 三角箭头
        formArrows.css({
          top: formT + "px"
        });
      }
      if (formBoxW < formW) {
        $(".form_arrows").show();
      } else {
        $(".form_arrows").hide();
      }
      $(".f_right").click(function () {
        scrollLeft = $(".search-form-box").scrollLeft();
        $(".search-form-box").scrollLeft(scrollLeft + 10);
        $(".theadClone").scrollLeft(scrollLeft + 10);
        $(".warrntName").show();
        if ($(window).scrollTop() > formT) {
          $(".warrntNameTop").show();
        } else {
          $(".warrntNameTop").hide();
        }
      });
      $(".f_left").click(function () {
        scrollLeft = $(".search-form-box").scrollLeft();
        $(".search-form-box").scrollLeft(scrollLeft - 10);
        $(".theadClone").scrollLeft(scrollLeft - 10);
        if (scrollLeft <= 0) {
          $(".warrntName").hide();
        }
      });

      // 监听屏幕
      $(window).resize(() => {
        formBoxW = $(".search-form-box").width();
        formW = $("#searchForm").width();
        formT = $("#SFormBox").offset().top;
        // warrntName
        $(".warrntName").width(
          $("#searchForm")
            .find(".h2")
            .find("th")
            .eq(0)
            .outerWidth(true)
        );
        $(".warrntName")
          .find("table")
          .width(
            $("#SFormBox")
              .find("table")
              .outerWidth(true)
          );
        $(".warrntName").css({
          top: formT + "px"
        });
        //warrntNameTop
        $(".warrntNameTop").width(
          $("#searchForm")
            .find(".h2")
            .find("th")
            .eq(0)
            .outerWidth(true)
        );
        $(".warrntNameTop")
          .find("table")
          .width(
            $("#SFormBox")
              .find("table")
              .outerWidth(true)
          );
        $(".warrntNameTop").css({
          top: $(".header-box").innerHeight() + "px"
        });
        // theadClone
        $(".theadClone").width(formBoxW);
        $(".theadClone")
          .find("table")
          .width(
            $("#SFormBox")
              .find("table")
              .outerWidth(true)
          );
        $(".theadClone").css({
          top: $(".header-box").innerHeight() + "px"
        });
        // 三角箭头
        titleTextH = $("#warransearch")
          .find(".title_text")
          .outerHeight(true);
        selectH = $(".search-select-show").outerHeight(true);
        if (formBoxW < formW) {
          $(".form_arrows").show();
        } else {
          $(".form_arrows").hide();
        }
        if ($(document).scrollTop() >= $("#SFormBox").outerHeight(true) - 500) {
          // 三角箭头
          if (_this.MoreFilterT) {
            formArrows.css({
              top: formT - 600 + "px"
            });
          } else {
            formArrows.css({
              top: formT - 200 + "px"
            });
          }
        } else {
          // 三角箭头
          formArrows.css({
            top: formT + "px"
          });
        }
      });
      // 监听滚动条
      $(window).scroll(function () {
        formT = $("#SFormBox").offset().top;
        if ($(this).scrollTop() > formT) {
          $(".theadClone").show();
          $(".warrntNameTop").show();
        } else {
          $(".theadClone").hide();
          $(".warrntNameTop").hide();
        }
        $(".warrntName").css({
          top: formT + "px"
        });
        $(".warrntNameTop").css({
          top: $(".header-box").innerHeight() + "px"
        });
        $(".theadClone").css({
          top: $(".header-box").innerHeight() + "px"
        });
        if ($(document).scrollTop() >= $("#SFormBox").outerHeight(true) - 500) {
          // 三角箭头
          if (_this.MoreFilterT) {
            formArrows.css({
              top: formT - 600 + "px"
            });
          } else {
            formArrows.css({
              top: formT - 200 + "px"
            });
          }
        } else {
          // 三角箭头
          formArrows.css({
            top: formT + "px"
          });
        }
      });
      // 监听#SFormBox滚动条
      $('#SFormBox').scroll(() => {
        scrollLeft = $(".search-form-box").scrollLeft()
        $(".search-form-box").scrollLeft(scrollLeft);
        $(".theadClone").scrollLeft(scrollLeft);
        if (scrollLeft <= 0) {
          $(".warrntName").hide();
        } else {
          $(".warrntName").show();
        }

      })
      this.$js.ScreenMonitor();
    },
    formHead: function () {
      $("#SFormBox")
        .find("#searchForm")
        .find(".h1")
        .empty();
      var formh1 = "";
      formh1 +=
        '<th colspan = "' +
        $("#SFormBox")
          .find("#searchForm")
          .find(".h2")
          .find(".h2_one").length +
        '" style = "background-color: #5bc2e7;  color: #454545;" >WARRANT</th>';
      if (
        $("#SFormBox")
          .find("#searchForm")
          .find(".h2")
          .find(".h2_two").length !== 0
      ) {
        formh1 +=
          '<th colspan="' +
          $("#SFormBox")
            .find("#searchForm")
            .find(".h2")
            .find(".h2_two").length +
          '" style="background-color: #0067b1">MARKET</th>';
      }
      $("#SFormBox")
        .find("#searchForm")
        .find(".h1")
        .append(formh1);
    },
    assignment: function (data) {
      var formText = "";
      var soldout = "";
      $(".search-form-box tbody").empty();
      for (var i = 0, max = data.length; i < max; i++) {
        if (Number(data[i][data[i].length - 2]) !== 0) {
          soldout =
            "<br><span style='display: inline-block; color: red; font-size: 10px; border: 1px solid; padding: 2px 4px; white-space: nowrap;'>Low On Inventory</span>";
        } else {
          soldout = "";
        }
        if (data[i][1] !== "RHB") {
          formText += '<tr class="form_c">';
          $.each(data[i], function (j, v) {
            if (j < 14) {
              if (j < 2) {
                if (j === 0) {
                  if (data[i][data[i].length - 1] !== " ") {
                    formText +=
                      '<td class="search-td-h"><a onclick="getRicToDetails(\'' +
                      data[i][data[i].length - 1] +
                      "' ," +
                      " '" +
                      data[i][0] +
                      "')\">" +
                      v +
                      "</a>" +
                      soldout +
                      "</td>";
                  } else {
                    formText +=
                      '<td class="search-td-h"> <a>' +
                      v +
                      "</a>" +
                      soldout +
                      "</td>";
                  }
                } else {
                  formText += "<td>" + v + "</td>";
                }
              } else {
                formText += '<td class="c_one_1">' + v + "</td>";
              }
            } else {
              if (j === 19) {
                if (v > 0) {
                  formText +=
                    '<td class="c_two_1" style="color: rgb(0, 153, 0)">' +
                    v +
                    "</td>";
                } else if (v < 0) {
                  formText +=
                    '<td class="c_two_1" style="color: rgb(255, 0, 0)">' +
                    v +
                    "</td>";
                } else if (v == 0) {
                  formText += '<td class="c_two_1">' + v + "</td>";
                }
              } else {
                formText += '<td class="c_two_1">' + v + "</td>";
              }
            }
          });
          formText += "</tr>";
        } else {
          formText += '<tr class="form_c">';
          $.each(data[i], function (j, v) {
            if (j < 14) {
              if (j < 2) {
                if (j === 0) {
                  if (data[i][data[i].length - 1] !== " ") {
                    formText +=
                      '<td class="search-td-h"><a onclick="getRicToDetails(\'' +
                      data[i][data[i].length - 1] +
                      "'," +
                      " '" +
                      data[i][0] +
                      "')\">" +
                      v +
                      "</a>" +
                      soldout +
                      "</td>";
                  } else {
                    formText +=
                      '<td class="search-td-h"> <a>' +
                      v +
                      "</a>" +
                      soldout +
                      "</td>";
                  }
                } else {
                  formText += '<td class="tdRhb">' + v + "</td>";
                }
              } else {
                formText += '<td class="c_one_1 tdRhb">' + v + "</td>";
              }
            } else {
              if (j === 19) {
                if (v > 0) {
                  formText +=
                    '<td class="c_two_1 tdRhb" style="color: rgb(0, 153, 0)">' +
                    v +
                    "</td>";
                } else if (v < 0) {
                  formText +=
                    '<td class="c_two_1 tdRhb" style="color: rgb(255, 0, 0)">' +
                    v +
                    "</td>";
                } else if (v == 0) {
                  formText += '<td class="c_two_1 tdRhb">' + v + "</td>";
                }
              } else {
                formText += '<td class="c_two_1 tdRhb">' + v + "</td>";
              }
            }
          });
          formText += "</tr>";
        }
      }
      $(".search-form-box tbody").append(formText);
      this.showForm();
      this.FormdynamicChange();
      let _this = this;
      window.getRicToDetails = function (ric, dsplay_nmll) {
        localStorage.setItem("detailsRic", ric);
        let routes = _this.$router.resolve({
          path: "/id/WarrantDetails",
          query: { dsplay_nmll: dsplay_nmll }
        });
        location.href = routes.href;
      };
    },
    printTable: function () {
      /*打印table*/
      $("#tableToExcel").click(() => {
        var otherdata = "";
        for (
          var i = 0;
          i <
          $("#searchForm>thead>tr")
            .eq(1)
            .find("th:visible").length;
          i++
        ) {
          otherdata += $("#searchForm>thead>tr")
            .eq(1)
            .find("th:visible")
            .eq(i)
            .text()
            .trim();
          if (
            i + 1 !=
            $("#searchForm>thead>tr")
              .eq(1)
              .find("th:visible").length
          ) {
            otherdata += ",";
          }
        }
        this.export_table_ws("#formData", otherdata, "Warrant Search");
      });
    },
    export_table_ws: function (selector, otherdata, name) {
      if (document.querySelectorAll(selector).length == 0) {
        alert('Selector "' + selector + '" not exists!');
        return false;
      }
      this.$js.export_csv(
        otherdata + "\n" + this.get_table_data_ws(selector),
        name
      );
    },
    get_table_data_ws: function (selector) {
      var data = "";
      for (var i = 0; i < $(selector).find("tr:visible").length; i++) {
        for (
          var j = 0;
          j <
          $(selector)
            .find("tr:visible")
            .eq(i)
            .find("td:visible").length;
          j++
        ) {
          data += $(selector)
            .find("tr:visible")
            .eq(i)
            .find("td:visible")
            .eq(j)
            .text()
            .trim()
            .split(",")
            .join("");
          if (
            j + 1 !=
            $(selector)
              .find("tr:visible")
              .eq(i)
              .find("td:visible").length
          ) {
            data += ",";
          }
        }

        data += "\n";
      }
      return data;
    }
  }
};
